import { NativeDateAdapter } from '@angular/material/core';

export class DatePickerAdapter extends NativeDateAdapter {
	// Format Date object to string
	override format(date: Date): string {
		const DAY = date.getDate();
		const MONTH = date.toLocaleString('default', { month: 'long' });
		const YEAR = date.getFullYear();

		return `${this.pad(DAY)} ${MONTH}, ${YEAR}`;
	}

	private pad(n: number): string {
		return n < 10 ? '0' + n : n.toString();
	}
}
